$(window).load(function () {
  //INITIAL
  $('#expVid').addClass('is-playing');

  //NAV
  $('.fullNavText').removeClass('active');
  $('.fullNavTextExperience').addClass('active');
  $('.fullNavTextExperience').bind('click', function (e) {
    e.preventDefault();
  });

  $('.scrollDownArrow').css('opacity', '1');

  //Scroll down arrow
  setTimeout(function () {
    $('.scrollDownArrow').css('opacity', '1');
    $('.arrowLines').css('width', '15px');
    $('.arrowLines').css('height', '3px');
  }, 300);
  setTimeout(function () {
    $('.arrowLines1')
      .css('transform', 'rotate(-35deg)')
      .css('margin-left', '-2px');
    $('.arrowLines2')
      .css('transform', 'rotate(35deg)')
      .css('margin-right', '-2px');
  }, 600);

  var hoverCount = 0;
  $('.scrollDownArrow').hover(function () {
    hoverCount++;
    //even odd hover detect
    var isEven = function (someNumber) {
      return someNumber % 2 === 0 ? true : false;
    };
    // on even hover do this
    if (isEven(hoverCount) === true) {
      // animate back to standard arrow
      $('.arrowLines').css('width', '15px');
      $('.downArrowLine').removeClass('animated');
      $('.arrowLines1')
        .css('transform', 'rotate(-35deg)')
        .css('margin-left', '-2px');
      $('.arrowLines2')
        .css('transform', 'rotate(35deg)')
        .css('margin-right', '-2px');
    }
    // on odd hover do this
    else if (isEven(hoverCount) === false) {
      // animated scroll down
      $('.arrowLines').css('width', '18px');
      $('.downArrowLine').addClass('animated');
      $('.arrowLines1')
        .css('transform', 'rotate(-215deg)')
        .css('margin-left', '-2px');
      $('.arrowLines2')
        .css('transform', 'rotate(215deg)')
        .css('margin-right', '-2px');
    }
  });

  $('.scrollDownArrow').click(function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: $('.exp-hero').height() }, 'slow');
  });

  //Email form on mobile
  $('.joinText').on('click', function (e) {
    const mq3 = window.matchMedia('(max-width: 769px)');

    if (mq3.matches) {
      $('.joinText').css('top', '5%');
      $('#email_signup').css('display', 'block');
    }
  });

  //Mobile nav reconfig
  const mqTab1 = window.matchMedia('(min-width: 1024px)');
  if (!mqTab1.matches) {
    $('#fullNavListRightLogin').remove();
    $('.horizontalAboutLine').remove();
    let aboutLinks = [];
    $('#fullNavListMid li').each(function () {
      aboutLinks.push(this);
    });
    $(aboutLinks).insertBefore('.fullNavTextGetJosh');
  }

  window.onscroll = function (e) {
    //BACK TO TOP
    if (
      window.innerHeight + window.scrollY >=
      $('#newbase-content').offset().top + $('#newbase-content').height() - 10
    ) {
      $('#backToTop').css('display', 'block').css('opacity', '0.5');
    } else {
      $('#backToTop').css('opacity', '0').css('display', 'none');
    }

    //Play videos once visible (partial for big monitor video, full for the phones)
    $('video').each(function () {
      if (this.id != 'play-once') {
        $(this)[0].muted = 'muted';
        if (this.id == 'big-vid') {
          if ($(this).visible(true)) {
            $(this)[0].play();
          } else {
            $(this)[0].pause();
            $(this)[0].currentTime = 0;
          }
        } else {
          if ($(this).visible()) {
            $(this)[0].play();
          } else {
            $(this)[0].pause();
            $(this)[0].currentTime = 0;
          }
        }
      }
    });
  };

  $('#backToTop').each(function () {
    $(this).click(function (e) {
      e.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    });
  });
});
